import React, { useState, useEffect, useRef } from 'react';
import Card from './components/Card';
import './App.css';

// Import images
import img1 from './images/img1.jpeg';
import img2 from './images/img2.jpeg';
import img3 from './images/img3.jpeg';
import img4 from './images/img4.jpeg';
import img5 from './images/img5.jpeg';
import img6 from './images/img6.jpeg';
import img7 from './images/img7.jpeg';
import img8 from './images/img8.jpeg';
import img9 from './images/img9.jpeg';
import img10 from './images/img10.jpeg';
import img11 from './images/img11.jpeg';
import img12 from './images/img12.jpeg';
import img13 from './images/img13.jpeg';
import img14 from './images/img14.jpeg';
import img15 from './images/img15.jpeg';
import img16 from './images/img16.jpeg';

// Use URLs for audio files in the public directory
const clickSoundUrl = `${process.env.PUBLIC_URL}/audio/click.mp3`;
const winSoundUrl = `${process.env.PUBLIC_URL}/audio/win.mp3`;
const matchSoundUrl = `${process.env.PUBLIC_URL}/audio/match.mp3`;
const bgMusicUrl = `${process.env.PUBLIC_URL}/audio/background.mp3`;


// Create an array with 16 unique images
const images = [
  img1, img2, img3, img4, 
  img5, img6, img7, img8,
  img9, img10, img11, img12,
  img13, img14, img15, img16
];

const App = () => {
  const [shuffledImages, setShuffledImages] = useState([]);
  const [flippedCardIds, setFlippedCardIds] = useState([]);
  const [matchedCards, setMatchedCards] = useState([]);
  const [playerTurn, setPlayerTurn] = useState(1);
  const [playerScores, setPlayerScores] = useState([0, 0, 0, 0, 0]); // Scores for 5 players
  const [numberOfPlayers, setNumberOfPlayers] = useState(2); // Default to 2 players
  const [winner, setWinner] = useState(null); // Track winner
  const [gameStarted, setGameStarted] = useState(false);

  const bgMusicRef = useRef(null);
  const clickSoundRef = useRef(null);
  const winSoundRef = useRef(null);
  const matchSoundRef = useRef(null);

  useEffect(() => {
    const initializeDeck = () => {
      const shuffled = [...images, ...images].sort(() => Math.random() - 0.5);
      setShuffledImages(shuffled);
    };

    initializeDeck();
  }, [numberOfPlayers]);

  useEffect(() => {
    if (matchedCards.length === shuffledImages.length) {
      const maxScore = Math.max(...playerScores);
      const winners = playerScores
        .map((score, index) => (score === maxScore ? index + 1 : null))
        .filter(num => num !== null);
  
      setWinner(winners.length > 1 ? `Players ${winners.join(' and ')}` : `Player ${winners[0]}`);
      
      if (bgMusicRef.current) {
        bgMusicRef.current.pause(); // Stop background music
      }

      if (winSoundRef.current) {
        winSoundRef.current.volume = 1.0;
        winSoundRef.current.play().catch(()=> {
          // Suppress the error without logging it {console.error('Error playing win sound:', error);}
        });
      }
    }
  }, [matchedCards]);
  
  useEffect(() => {
    return () => {
      if (bgMusicRef.current) {
        bgMusicRef.current.pause(); // Stop background music when component unmounts or game resets
      }
    };
  }, []);

  const handleCardClick = (id) => {
  if (!gameStarted) {
    setGameStarted(true);
    if (bgMusicRef.current) {
      bgMusicRef.current.play().catch(error => {
        console.error('Background music play error:', error);
      });
    }
  }

  if (flippedCardIds.length === 2) return;

  if (flippedCardIds.length === 0) {
    // Play click sound for the first card flip
    if (clickSoundRef.current) {
      clickSoundRef.current.play().catch(error => {
        console.error('Click sound play error:', error);
      });
    }
  }

  if (clickSoundRef.current) {
    clickSoundRef.current.play().catch(error => {
      console.error('Click sound play error:', error);
    });
  }

  if (flippedCardIds.includes(id) || matchedCards.includes(id)) return;

  setFlippedCardIds((prev) => [...prev, id]);

  if (flippedCardIds.length === 1) {
    const firstId = flippedCardIds[0];
    const secondId = id;

    if (shuffledImages[firstId] === shuffledImages[secondId]) {
      const newScores = [...playerScores];
      newScores[playerTurn - 1] += 1;
      setPlayerScores(newScores);

      setMatchedCards((prev) => [...prev, firstId, secondId]);
      setFlippedCardIds([]);
      if (matchSoundRef.current) {
        matchSoundRef.current.play().catch(error => {
          console.error('Match sound play error:', error);
        });
      }
    } else {
      // Play click sound if the second card does not match
      if (clickSoundRef.current) {
        clickSoundRef.current.play().catch(error => {
          console.error('Click sound play error:', error);
        });
      }

      setTimeout(() => {
        setFlippedCardIds([]);
        if (numberOfPlayers > 1) {
          setPlayerTurn((prev) => (prev % numberOfPlayers) + 1);
        } else {
          handleBotTurn();
        }
      }, 1000);
    }
  }
};


  const handleBotTurn = () => {
    setTimeout(() => {
      const availableCards = shuffledImages
        .map((img, index) => !flippedCardIds.includes(index) && !matchedCards.includes(index) ? index : null)
        .filter(index => index !== null);

      if (availableCards.length < 2) return;

      const botFlippedIds = [availableCards[Math.floor(Math.random() * availableCards.length)],
                             availableCards[Math.floor(Math.random() * availableCards.length)]];

      if (shuffledImages[botFlippedIds[0]] === shuffledImages[botFlippedIds[1]]) {
        const newScores = [...playerScores];
        newScores[1] += 1; // Bot is always player 2
        setPlayerScores(newScores);
        setMatchedCards((prev) => [...prev, botFlippedIds[0], botFlippedIds[1]]);
        if (matchSoundRef.current) {
          matchSoundRef.current.play(); // Play match sound for bot's correct match
        }
      }

      setFlippedCardIds(botFlippedIds);

      setTimeout(() => {
        setFlippedCardIds([]);
        setPlayerTurn(1); // Switch back to player
      }, 1000);
    }, 1000);
  };

  const isGameCompleted = matchedCards.length === shuffledImages.length;

  const resetGame = () => {
    const newShuffled = [...images, ...images].sort(() => Math.random() - 0.5);
    setShuffledImages(newShuffled);
    setFlippedCardIds([]);
    setMatchedCards([]);
    setPlayerScores(Array(numberOfPlayers).fill(0));
    setPlayerTurn(1);
    setWinner(null);
    setGameStarted(false); // Stop background music as game restarts
  };

  return (
    <div className={`game-container ${winner ? 'game-complete' : ''}`}>
      <audio ref={bgMusicRef} src={bgMusicUrl} preload="auto" loop />
      <audio ref={clickSoundRef} src={clickSoundUrl} preload="auto" />
      <audio ref={winSoundRef} src={winSoundUrl} preload="auto" />
      <audio ref={matchSoundRef} src={matchSoundUrl} preload="auto" />

      <h1 className="game-heading">X</h1>
      {!gameStarted && (
        <div className="player-selection">
          <div className="player-buttons-container">
            <button
              className={`player-button ${numberOfPlayers === 1 ? 'selected' : ''}`}
              onClick={() => setNumberOfPlayers(1)}
            >
              Play with Bot
            </button>
            {[2, 3, 4, 5].map(num => (
              <button
                key={num}
                className={`player-button ${numberOfPlayers === num ? 'selected' : ''}`}
                onClick={() => setNumberOfPlayers(num)}
              >
                {num} Player{num > 1 ? 's' : ''}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="scores">
        {numberOfPlayers === 1 && (
          <>
            <div className={`score player ${playerTurn === 1 ? 'highlight' : ''}`}>
              Player Score: {playerScores[0]}
            </div>
            <div className={`score bot ${playerTurn === 2 ? 'highlight' : ''}`}>
              <div>Bot</div>
              <div>Score: {playerScores[1]}</div>
            </div>
          </>
        )}
        {numberOfPlayers > 1 && Array.from({ length: numberOfPlayers }, (_, i) => (
          <div
            key={i}
            className={`score player${i + 1} ${playerTurn === i + 1 ? 'highlight' : ''}`}
          >
            Player {i + 1} Score: {playerScores[i]}
          </div>
        ))}
      </div>
      <div className="card-grid">
        {shuffledImages.map((image, index) => (
          <Card
            key={index}
            id={index}
            image={image}
            onClick={handleCardClick}
            isFlipped={flippedCardIds.includes(index) || matchedCards.includes(index)}
          />
        ))}
      </div>
      {isGameCompleted && (
        <div className="game-complete">
          <p>Congratulations! {winner} Wins!</p>
        </div>
      )}
      <button className="restart-button" onClick={resetGame}>
        Restart Game
      </button>
    </div>
  );
};

export default App;
