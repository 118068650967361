import React from 'react';
import '../styles/Card.css'; 
import img0 from '../images/img0.jpeg'; // Import the default image

const Card = ({ image, onClick, isFlipped, id }) => {
  return (
    <div
      className={`card ${isFlipped ? 'flip' : ''}`}
      onClick={() => onClick(id)}
    >
      <div className="card-inner">
        <div className="card-front">
          {/* Default image shown when card is not flipped */}
          <img src={img0} alt="Default" /> 
        </div>
        <div className="card-back">
          {/* Actual image shown when card is flipped */}
          <img src={image} alt="Card" /> 
        </div>
      </div>
    </div>
  );
};

export default Card;
